import { useContext } from "react";
import { GlobalContext } from "../components/data/GlobalContext";
import Layout from "../components/Layout";
import PagesHeader from "../components/PagesHeader";
import GalleryContent from "../components/gallery/GalleryContent";

const Gallery = () => {
    const dbData = useContext(GlobalContext);
    return (
        <Layout pageTitle="Gallery" >
            <PagesHeader title="Gallery" crumbTitle="Gallery" pageHeaderBg={dbData.stock[4]} />
            <div className="text-center py-5">
                <h1 className="text-capitalize">our recent projects</h1>
            </div>
            {
                dbData.landings.map((item, index) => {
                    return (
                        <div key={index}>
                            <h2 className="text-capitalize text-center fw-bold p-5">{item.description}</h2>
                            <GalleryContent galleryImages={item.gallery} />
                        </div>
                    )
                })
            }
        </Layout>
    );
}
export default Gallery;